import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../../hooks'
import { RiskReliefDeviationClassificationRunResult } from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import RiskReliefSmartFunctionHeader from '../../components/RiskReliefSmartFunctionHeader'
import RiskReliefCell from '../../components/RiskReliefCell'

const RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader = () => {
  const navigate = useNavigate()
  const { workspaceId, algorithmRunId, deviationId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })

  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction/${deviationId}`,
    [selectedWorkspace?.id, deviationId]
  )

  const deviationClassificationGlobal = useMemo(
    () =>
      selectedAlgorithmRun?.runResult
        ? (
            selectedAlgorithmRun?.runResult as RiskReliefDeviationClassificationRunResult
          ).deviationClassificationGlobal
        : undefined,
    [selectedAlgorithmRun]
  )

  const onRunChange = useCallback(
    (algorithmRunId?: string) => {
      const url = `${routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION}/${workspaceId}/${algorithmRunId}`
      navigate(url)
    },
    [navigate, workspaceId]
  )

  return (
    <RiskReliefSmartFunctionHeader
      backButtonUrl={backButtonUrl}
      onRunChange={onRunChange}
    >
      <RiskReliefCell
        title={t(
          'smartFunctionDetailsPage.riskReliefClassificationDeviation.header.occurrenceTitle'
        )}
        deviationValue={deviationClassificationGlobal?.occurrence}
      />
      <RiskReliefCell
        title={t(
          'smartFunctionDetailsPage.riskReliefClassificationDeviation.header.severityTitle'
        )}
        deviationValue={deviationClassificationGlobal?.severity}
      />
      <RiskReliefCell
        title={t(
          'smartFunctionDetailsPage.riskReliefClassificationDeviation.header.detectabilityTitle'
        )}
        deviationValue={deviationClassificationGlobal?.detectability}
      />
      <RiskReliefCell
        title={t(
          'smartFunctionDetailsPage.riskReliefClassificationDeviation.header.riskTitle'
        )}
        risk={deviationClassificationGlobal?.risk}
        cellType="risk"
      />
    </RiskReliefSmartFunctionHeader>
  )
}

export default RiskReliefDeviationClassificationSmartFunctionDetailsPageHeader
