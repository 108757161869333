import { WarningOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Badge,
  DocumentDetailsLayout,
  SectionLoadingState,
} from '../../components'
import { DocumentDetailsFormatted } from '../../components/DocumentDetailsLayout'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  searchReviewTypes,
} from '../../constants/constants'
import { useProcessDeviation, useSearchReviews } from '../../hooks'
import { routePaths } from '../RootPage'
const { Title, Paragraph } = Typography

const Section = styled.div`
  margin-bottom: 24px;
`

const SectionTitle = styled(Title).attrs({ level: 5 })`
  margin-bottom: 12px !important;
  color: var(--color-text-secondary) !important;
  font-size: 12px !important;
`

interface HistoricalReviewRightColumnProps {
  tags: { key: string; count: number; score: number }[]
  deviationSummary?: string
  capaSummary?: string
}

const HistoricalReviewRightColumn = ({
  tags,
  deviationSummary,
  capaSummary,
}: HistoricalReviewRightColumnProps) => {
  return (
    <>
      <Section>
        <SectionTitle>
          {t('searchReviewDetailsPage.historicalReviewSearchTable.rootCause')}
        </SectionTitle>
        {tags.map((tag) => (
          <Badge color={BadgeColors.WARNING} icon={<WarningOutlined />}>
            {tag.key}
          </Badge>
        ))}
      </Section>

      <Section>
        <SectionTitle>
          {t(
            'searchReviewDetailsPage.historicalReviewSearchTable.deviationSummary'
          )}
        </SectionTitle>
        <Paragraph>
          {deviationSummary ||
            t(
              'searchReviewDetailsPage.historicalReviewSearchTable.noSummaryGenerated'
            )}
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>
          {t('searchReviewDetailsPage.historicalReviewSearchTable.capaSummary')}
        </SectionTitle>
        <Paragraph>
          {capaSummary ||
            t(
              'searchReviewDetailsPage.historicalReviewSearchTable.noSummaryGenerated'
            )}
        </Paragraph>
      </Section>
    </>
  )
}

const SearchReviewSearchQueryDocumentDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {
    selectedSearchReview,
    selectedSearchQuery,
    selectedDocument,
    summarizeSearchReviewSearchQueryResult,
    refreshSearchReviewList,
    saveSearchQueryInclusion,
    loading,
  } = useSearchReviews(params)
  const [inclusionSwitchLoading, setInclusionSwitchLoading] = useState(false)
  const [fetchDone, setFetchDone] = useState(false)
  const { getTop2Tags } = useProcessDeviation({})

  useEffect(() => {
    // This will refresh the page whenever the URL changes
    if (!fetchDone) {
      refreshSearchReviewList()
      setFetchDone(true)
    }
  }, [params, refreshSearchReviewList, fetchDone])

  const getDocumentIndex = useCallback(
    () =>
      selectedDocument && selectedSearchQuery?.searchResults
        ? selectedSearchQuery.searchResults.indexOf(selectedDocument)
        : 0,
    [selectedDocument, selectedSearchQuery?.searchResults]
  )
  const getDocumentIndexText = useCallback(
    () =>
      selectedDocument && selectedSearchQuery?.searchResults
        ? getDocumentIndex() + 1
        : NO_DATA_PLACEHOLDER,
    [selectedDocument, selectedSearchQuery?.searchResults, getDocumentIndex]
  )

  const handlePreviousDocumentClick = () => {
    const lastIndex = selectedSearchQuery?.searchResults
      ? selectedSearchQuery?.searchResults.length - 1
      : 0
    const documentIndex = getDocumentIndex()
    const newDocumentIndex = documentIndex > 0 ? documentIndex - 1 : lastIndex
    const newSelectedDocumentId =
      selectedSearchQuery?.searchResults[newDocumentIndex].id
    setFetchDone(false)
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedSearchReview?.id}/${selectedSearchQuery?.searchEvent}/${newSelectedDocumentId}`
    )
  }

  const handleNextDocumentClick = () => {
    const documentIndex = getDocumentIndex()
    const newDocumentIndex =
      selectedSearchQuery?.searchResults &&
      documentIndex < selectedSearchQuery?.searchResults.length - 1
        ? documentIndex + 1
        : 0
    const newSelectedDocumentId =
      selectedSearchQuery?.searchResults[newDocumentIndex].id
    setFetchDone(false)
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedSearchReview?.id}/${selectedSearchQuery?.searchEvent}/${newSelectedDocumentId}`
    )
  }

  const isDocumentIncluded = useMemo(
    () =>
      selectedSearchReview?.includedSearchResults?.includes(
        selectedDocument?.id || ''
      ),
    [selectedDocument, selectedSearchReview?.includedSearchResults]
  )

  const handleSwitchClick = useCallback(async () => {
    setInclusionSwitchLoading(true)

    const handleResponse = () => {
      setInclusionSwitchLoading(false)
    }

    try {
      await saveSearchQueryInclusion({
        recordId: selectedDocument?.id || '',
        navigate: navigate,
      })
      handleResponse()
    } catch (error) {
      console.error('axios fetch error', error)
      handleResponse()
    }
  }, [saveSearchQueryInclusion, selectedDocument, navigate])

  const formattedDocumentDetails: DocumentDetailsFormatted = useMemo(
    () => ({
      _id: selectedDocument?.id,
      authors: selectedDocument?.authors,
      dataSource: selectedDocument?.dataSource,
      originalPublicationDate: selectedDocument?.publicationDate || '',
      language: selectedDocument?.language || '',
      publicationName: selectedDocument?.publicationName || '',
      title: selectedDocument?.title || NO_DATA_PLACEHOLDER,
      url: selectedDocument?.documentLink,
      csv: selectedDocument?.csv,
      summary: selectedDocument?.summary,
      summaryStatus: selectedDocument?.summaryStatus,
    }),
    [selectedDocument]
  )
  const handleSummarize = () => {
    if (selectedSearchReview?.id && selectedDocument?.id)
      summarizeSearchReviewSearchQueryResult({
        searchReviewId: selectedSearchReview?.id || '',
        resultId: selectedDocument?.id,
      })
  }

  const top2Tags = useMemo(() => {
    return selectedDocument?.tagDetails
      ? getTop2Tags(selectedDocument?.tagDetails)
      : []
  }, [selectedDocument, getTop2Tags])

  const rightColumn = useMemo(() => {
    if (
      selectedSearchReview?.reviewType !==
      searchReviewTypes.HISTORICAL_NC_REVIEW
    ) {
      return null
    }
    return (
      <HistoricalReviewRightColumn
        tags={top2Tags}
        deviationSummary={
          selectedDocument?.summary ||
          t(
            'searchReviewDetailsPage.historicalReviewSearchTable.noSummaryGenerated'
          )
        }
        capaSummary={NO_DATA_PLACEHOLDER}
      />
    )
  }, [selectedSearchReview, selectedDocument, top2Tags])

  return selectedDocument && !loading ? (
    <DocumentDetailsLayout
      handleNextDocumentClick={handleNextDocumentClick}
      handlePreviousDocumentClick={handlePreviousDocumentClick}
      getDocumentIndex={getDocumentIndexText}
      length={selectedSearchQuery?.searchResults.length}
      document={formattedDocumentDetails}
      includedSwitchValue={isDocumentIncluded}
      onSwitchClick={handleSwitchClick}
      onSummarize={handleSummarize}
      switchLoading={inclusionSwitchLoading}
      backUrl={`${routePaths.SEARCH_REVIEW_SEARCH}/${selectedSearchReview?.id}/${selectedSearchQuery?.id}`}
      rightColumn={rightColumn}
    />
  ) : (
    <SectionLoadingState />
  )
}

export default SearchReviewSearchQueryDocumentDetailsPage
