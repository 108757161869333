import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Badge,
  ListPageLayout,
  TableBoldColumnContainer,
} from '../../../components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  RiskReliefDeviationStatus,
  dateFormat,
  riskReliefDeviationStatuses,
} from '../../../constants/constants'
import { useSearchReviews, useUsers, useWorkspaceDetails } from '../../../hooks'
import {
  SearchQuery,
  SearchReview,
} from '../../../store/reducers/searchReviewReducer'
import { routePaths } from '../../RootPage'
import { workSpaceTabs, workspacePanelTypes } from '../WorkspaceDetailsPage'

interface ExtendedSearchQuery extends SearchQuery {
  key: string
  ownerName: string
  searchReviewTitle?: string
  searchQueryText?: string
  publicationDate?: string
  deviationStatus: RiskReliefDeviationStatus
}

interface WorkspaceDeviationsPanelProps {
  statusFilter: RiskReliefDeviationStatus
  deviationOwner: string
}

interface SearchReviewDict {
  [key: string]: SearchReview
}

interface SearchQueryDict {
  [key: string]: SearchQuery
}

const WorkspaceDeviationsPanel = ({
  statusFilter,
  deviationOwner,
}: WorkspaceDeviationsPanelProps) => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const { findUser } = useUsers({})

  const { getSearchReviewsBatch } = useSearchReviews({
    preventFetch: true,
  })

  const [searchReviews, setSearchReviews] = useState<SearchReviewDict>({})
  const [searchQueries, setSearchQueries] = useState<SearchQueryDict>({})
  const [loading, setLoading] = useState(true)
  const [reviewsLoading, setReviewsLoading] = useState(false)

  const buildDictionaries = (reviews: SearchReview[]) => {
    return reviews.reduce<{
      reviewsDict: SearchReviewDict
      queriesDict: SearchQueryDict
    }>(
      (acc, review) => {
        if (!review) return acc

        acc.reviewsDict[review.id] = review

        review.searchQueries?.forEach((query) => {
          if (query?.id) {
            acc.queriesDict[query.id] = query
          }
        })

        return acc
      },
      { reviewsDict: {}, queriesDict: {} }
    )
  }

  const fetchSearchReviewData = useCallback(async () => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (!searchReviewIds?.length) {
      setLoading(false)
      return
    }

    try {
      setReviewsLoading(true)

      const fetchedReviews = await getSearchReviewsBatch(searchReviewIds)
      const { reviewsDict, queriesDict } = buildDictionaries(fetchedReviews)
      setSearchReviews(reviewsDict)
      setSearchQueries(queriesDict)
    } catch (error) {
      console.error('Error fetching search review data:', error)
    } finally {
      setLoading(false)
      setReviewsLoading(false)
    }
  }, [selectedWorkspace, getSearchReviewsBatch])

  useEffect(() => {
    fetchSearchReviewData()
  }, [fetchSearchReviewData])

  const formattedData: ExtendedSearchQuery[] = useMemo(() => {
    const queries = Object.values(searchQueries)

    return queries.map((query) => {
      const searchReview = searchReviews[query?.literatureReview]
      const owner = findUser(query.searchActionBy?.id ?? '')?.displayName ?? ''
      return {
        ...query,
        ownerName: owner,
        searchReviewTitle: searchReview.title,
        searchQueryText: query.searchText,
        publicationDate: query.searchDate,
        key: `${query.searchSource.id}-${query.id}`,
        deviationStatus: riskReliefDeviationStatuses.OPENED,
      } as ExtendedSearchQuery
    })
  }, [searchQueries, searchReviews, findUser])

  const columns: ColumnsType<ExtendedSearchQuery> = [
    {
      title: t('Search Query'),
      dataIndex: 'searchQueryText',
      key: 'searchQueryText',
      width: '40%',
      render: (text, record) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Historical Review'),
      dataIndex: 'searchReviewTitle',
      key: 'searchReviewTitle',
      width: '20%',
      render: (text) => text || NO_DATA_PLACEHOLDER,
    },

    {
      title: t('Date'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('Owner'),
      dataIndex: 'ownerName',
      key: 'ownerName',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Status'),
      dataIndex: 'deviationStatus',
      key: 'deviationStatus',
      render: (text) => (
        <>
          {text ? (
            <Badge
              color={
                text === riskReliefDeviationStatuses.OPENED
                  ? BadgeColors.STATUS_OPENED
                  : BadgeColors.STATUS_CLOSED
              }
            >
              {t(`workspaceDetailsPage.deviationStatus.${text}`)}
            </Badge>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
  ]

  const handleClick = (record: ExtendedSearchQuery) => {
    navigate(
      `${routePaths.WORKSPACE_DETAILS}/${workspaceId}/${workspacePanelTypes.OVERVIEW}/${workSpaceTabs.INFO}/${record.id}`
    )
  }

  return (
    <ListPageLayout>
      <Table
        data-testid="data-sources-list-table"
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        loading={loading || reviewsLoading}
        scroll={{ y: 'calc(100vh - 170px)' }}
        onRow={(record) => ({
          onClick: () => handleClick(record),
        })}
      />
    </ListPageLayout>
  )
}

export default WorkspaceDeviationsPanel
