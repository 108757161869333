import { NavigateFunction } from 'react-router-dom'
import { urlList } from '../constants/urlConstant'
import {
  AlgorithmRun,
  AlgorithmRunConfigValues,
  AlgorithmRunResult,
  SmartFunctionRunStatus,
} from '../store/reducers/workspaceReducer'
import apiService from './apiService'

const baseUrl = urlList.WORKSPACES

interface RunAlgorithmProps {
  id: string
  navigate?: NavigateFunction
  config?: AlgorithmRunConfigValues
}

interface CancelRunProps {
  id: string
  algorithmRunId: string
  navigate: NavigateFunction
}

const runAlgorithm = async ({ id, navigate, config }: RunAlgorithmProps) => {
  return apiService
    .saveItem(`${baseUrl}/${id}/algorithmRuns`, navigate, {
      config: config,
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

const cancelRun = async ({ id, algorithmRunId, navigate }: CancelRunProps) => {
  return apiService
    .deleteItem(
      `${baseUrl}/${id}/algorithmRuns/${algorithmRunId}/cancel`,
      navigate
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

interface getWorkspaceAlgorithmRunProps {
  workspaceId: string
  algorithmRunId: string
}

const getWorkspaceAlgorithmRun = async ({
  workspaceId,
  algorithmRunId,
}: getWorkspaceAlgorithmRunProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}`)
    .then((response: AlgorithmRun) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportRunProps {
  workspaceId: string
  algorithmRunId: string
}

const exportRun = async ({ workspaceId, algorithmRunId }: ExportRunProps) => {
  return apiService
    .fetchItems(
      `${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}/result/csv`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const getSmartFunctionRunResults = async ({
  workspaceId,
  algorithmRunId,
}: {
  workspaceId: string
  algorithmRunId: string
}): Promise<AlgorithmRunResult> => {
  return apiService
    .fetchItems(
      `${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}/results`
    )
    .then((response) => {
      return response as AlgorithmRunResult
    })
    .catch((error) => {
      console.error('Error fetching smart function run results:', error)
      throw error
    })
}

const getSmartFunctionRunStatus = async ({
  workspaceId,
  algorithmRunId,
}: {
  workspaceId: string
  algorithmRunId: string
}): Promise<SmartFunctionRunStatus> => {
  return apiService
    .fetchItems(
      `${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}/statuses`
    )
    .then((response) => {
      return response as SmartFunctionRunStatus
    })
    .catch((error) => {
      console.error('Error fetching smart function run status:', error)
      throw error
    })
}

interface GetSmartFunctionRunWorkflowNodeProps {
  nodeId: string
  algorithmRunId: string
  workspaceId: string
}

const getSmartFunctionRunWorkflowNode = async ({
  nodeId,
  algorithmRunId,
  workspaceId,
}: GetSmartFunctionRunWorkflowNodeProps): Promise<any> => {
  return apiService
    .fetchItems(
      `${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}/workflowNodes/${nodeId}`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('Error fetching smart function run workflow nodes:', error)
      throw error
    })
}

interface ResumeSmartFunctionWorkflowProps {
  workspaceId: string
  algorithmRunId: string
  config: AlgorithmRunConfigValues
  navigate: NavigateFunction
}

const resumeSmartFunctionWorkflow = async ({
  workspaceId,
  algorithmRunId,
  config,
  navigate,
}: ResumeSmartFunctionWorkflowProps) => {
  return apiService
    .saveItem(
      `${baseUrl}/${workspaceId}/algorithmRuns/${algorithmRunId}/resume`,
      navigate,
      {
        config,
      }
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('Error resuming smart function workflow:', error)
      throw error
    })
}

const smartFunctionService = {
  runAlgorithm,
  cancelRun,
  exportRun,
  getWorkspaceAlgorithmRun,
  getSmartFunctionRunStatus,
  getSmartFunctionRunResults,
  getSmartFunctionRunWorkflowNode,
  resumeSmartFunctionWorkflow,
}

export default smartFunctionService
