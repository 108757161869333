import React from 'react'
import { styled } from 'styled-components'
import {
  NO_DATA_PLACEHOLDER,
  RiskReliefDeviationRisk,
  RiskReliefDeviationSeverity,
} from '../../../constants/constants'
import { t } from 'i18next'

const CellContainer = styled.div<{ $cellType?: CellType }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px
    ${(props) =>
      props.$cellType === 'risk'
        ? props.theme.token.colorBgContainer
        : props.theme.token.colorBorderSecondary};
  background-color: ${(props) =>
    props.$cellType === 'risk'
      ? props.theme.token.colorBgContainer
      : props.theme.token.colorBgLayout};
  width: 100%;
`

const FirstLine = styled.div`
  opacity: 0.45;
  min-width: 100%;
`

const SecondLine = styled.div`
  font-height: 32px;
  font-weight: 600;
`

type CellType = 'basic' | 'risk'

interface RiskReliefCellProps {
  title: string
  deviationValue?: RiskReliefDeviationSeverity
  risk?: RiskReliefDeviationRisk
  cellType?: CellType
}

const RiskReliefCell = ({
  title,
  deviationValue,
  risk,
  cellType,
}: RiskReliefCellProps) => {
  return (
    <CellContainer $cellType={cellType}>
      <FirstLine>{title}</FirstLine>
      <SecondLine>
        {cellType === 'risk'
          ? risk
            ? t(
                `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationRiskValues.${risk}`
              )
            : NO_DATA_PLACEHOLDER
          : deviationValue
          ? t(
              `smartFunctionDetailsPage.riskReliefClassificationDeviation.deviationValues.${deviationValue}`
            )
          : NO_DATA_PLACEHOLDER}
      </SecondLine>
    </CellContainer>
  )
}

export default RiskReliefCell
